import PropTypes from 'prop-types';

const Loading = ({ className = '' }) => (
  <div className={className}>
    <p>Loading, please wait...</p>
  </div>
);

Loading.propTypes = {
  className: PropTypes.string,
};

export default Loading;
