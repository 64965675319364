import PropTypes from 'prop-types';
import { getPages } from '../../lib/getPages';

const Pagination = ({
  offset,
  setOffset,
  resultsPerPage,
  totalRows,
  styles,
}) => {
  const totalPages = Math.ceil(totalRows / resultsPerPage);
  const currentPage = (offset / resultsPerPage);

  const renderPages = () => {
    const pageButtons = [];
    const pages = getPages(currentPage, 5, totalPages);

    pages.forEach((page) => {
      pageButtons.push(<button
        className={`${styles.btn} ${offset === (page - 1) * resultsPerPage ? styles.pagination__current_page : ''}`}
        key={page}
        onClick={() => setOffset((page - 1) * resultsPerPage)}>
        {page}
      </button>);
    });
    return pageButtons;
  };

  return (
    <div className={styles.pagination}>
      <button
        className={styles.btn}
        disabled={(offset - resultsPerPage < 0)}
        onClick={() => setOffset(0)}>
        First
      </button>
      <button
        className={styles.btn}
        disabled={(offset - resultsPerPage < 0)}
        onClick={() => setOffset(offset - resultsPerPage)}>
        Previous
      </button>
      {renderPages()}
      <button
        className={styles.btn}
        disabled={(offset + resultsPerPage >= totalRows)}
        onClick={() => setOffset(offset + resultsPerPage)}>
        Next
      </button>
      <button
        className={styles.btn}
        disabled={(offset + resultsPerPage >= totalRows)}
        onClick={() => setOffset(resultsPerPage * (totalPages - 1))}>
        Last
      </button>
    </div>
  );
};

Pagination.propTypes = {
  offset: PropTypes.number.isRequired,
  setOffset: PropTypes.func.isRequired,
  resultsPerPage: PropTypes.number.isRequired,
  totalRows: PropTypes.number.isRequired,
  styles: PropTypes.object.isRequired,
};

export default Pagination;
