export const getPages = (currentPage, displayedPages, totalPages) => {
  const current = currentPage + 1;
  const pages = [...Array(totalPages).keys()].map((x) => x + 1);

  // grab the halves on either side of the current page
  const leftHalf = Math.floor((displayedPages - 1) / 2);
  const rightHalf = displayedPages - leftHalf;
  // count down and up from the currentPage
  const firstDisplayedPage = current - leftHalf - 1;
  const lastDisplayedPage = current + rightHalf - 1;

  // we're being asked to display more pages than we have or displayedPages is just silly
  // so just show all our pages
  if (displayedPages < 1 || displayedPages > totalPages) {
    return pages;
  }

  // we're on the first page or the firstDisplayedPage is negative
  // so just show the first set of pages
  if (current === 1 || firstDisplayedPage < 1) {
    return pages.slice(0, displayedPages);
  }

  // we're on the last page or the lastDisplayedPage goes beyond the pages we have
  // so just show the last set of pages
  if (current === totalPages || lastDisplayedPage > totalPages) {
    return pages.slice(-displayedPages);
  }

  // we're somewhere nice in the middle so just show the range
  return pages.slice(firstDisplayedPage, lastDisplayedPage);
};

export default getPages;
