import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Filters from '../components/Filters';
import DataTable from '../components/DataTable';
import Pagination from '../components/Pagination';
import Loading from '../components/Loading';

const EventsListPage = ({
  data,
  styles,
  loadData,
  reloading,
}) => {
  const [filters, setFilters] = useState(null);
  const [offset, setOffset] = useState(null);
  const [sort, setSort] = useState(null);
  const resultsPerPage = data?.configuration?.displaySettings?.resultsPerPage || 25;

  useEffect(() => {
    if (filters !== null || offset !== null || sort !== null) {
      loadData(filters, offset, sort);
    }
  }, [filters, offset, sort, loadData]);

  return (
  <div className={styles.widget_container}>
    {reloading && (<Loading className={styles.widget_loading} />)}
    <div className={styles.widget_wrapper}>
      <Filters
        styles={styles}
        setFilters={setFilters}
        setOffset={setOffset}
        eventTypes={data.eventTypes}
        widgetType={data.configuration.type}
        settings={data.configuration.displaySettings.filters || {}}
        regions={data.regions} />
      <DataTable
        columns={data.columns}
        rows={data.rows}
        setSort={setSort}
        initialSort={data.configuration.displaySettings.initialSort}
        widgetType={data.configuration.type}
        isParticipantsList={!!(filters && filters.searchByParticipant)}
        styles={styles} />
      {data.totalRows > resultsPerPage && (
        <Pagination
          offset={offset || 0}
          setOffset={setOffset}
          resultsPerPage={resultsPerPage}
          totalRows={data.totalRows}
          styles={styles}
        />
      )}
    </div>
  </div>);
};

EventsListPage.propTypes = {
  data: PropTypes.object,
  styles: PropTypes.object.isRequired,
  loadData: PropTypes.func.isRequired,
  reloading: PropTypes.bool.isRequired,
};

export default EventsListPage;
