const injectCss = (styles, settings) => {
  const css = [];
  const documentHead = document.head || document.getElementsByTagName('head')[0];
  const customStyles = document.createElement('style');

  // General
  css.push(`.${styles.widget_container} { background-color: #${settings.widgetBackgroundColor}; }`);
  css.push(`.${styles.widget_container} { font-size: ${settings.bodyFontSize}px; }`);
  css.push(`.${styles.widget_container} { color: #${settings.bodyFontColor}; }`);

  // Mobile
  css.push(`.${styles.mobile_event_link} { color: #${settings.linksFontColor}; }`);

  // Table
  css.push(`.${styles.data_table} thead { background-color: #${settings.headerBackgroundColor}; }`);
  css.push(`.${styles.table_header} { font-size: ${settings.headerFontSize}px; color: #${settings.headerFontColor} }`);
  css.push(`.${styles.table_link} {
  color: #${settings.detailsLinkFontColor};
  background-color: #${settings.detailsLinkBackgroundColor};
  border-color: #${settings.detailsLinkBackgroundColor};
  }`);
  css.push(`.${styles.data_table} tbody > tr:nth-child(odd) { background-color: #${settings.oddRowBackgroundColor}; }`);
  css.push(`.${styles.data_table} tbody > tr:nth-child(even) { background-color: #${settings.evenRowBackgroundColor}; }`);
  css.push(`.${styles.data_table} a:not(.${styles.table_link}) { color: #${settings.linksFontColor}; }`);
  css.push(`.${styles.data_table}
  a:not(.${styles.table_link}):hover,
  a:not(.${styles.table_link}):focus,
  { color: #${settings.linksFontColor}; }`);

  // Search control
  css.push(`.${styles.btn_submit} { background-color: #${settings.detailsLinkBackgroundColor}; border-color: #${settings.detailsLinkBackgroundColor}; }`);
  css.push(`.${styles.btn_submit}:focus, .${styles.btn_submit}:hover { background-color: #${settings.detailsLinkBackgroundColor}; border-color: #${settings.detailsLinkBackgroundColor}; }`);

  // Pagination
  css.push(`.${styles.pagination} button.${styles.pagination__current_page} { background-color: #${settings.detailsLinkBackgroundColor}; border-color: #${settings.detailsLinkBackgroundColor}; }`);

  documentHead.appendChild(customStyles);
  customStyles.appendChild(document.createTextNode(css.join('')));
};

export default injectCss;
