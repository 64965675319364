import PropTypes from 'prop-types';
import { ReactComponent as NotFoundImage } from './assets/empty-404--forest.svg';

const Error = ({
  data = {},
  styles,
  onTryAgain = () => {},
}) => (
  <>
    {data.status === 500 ? (
      <div className={`${styles.error} ${styles.error__500}`}>
        <p>{data.message ? data.message : 'Unable to load widget'}</p>
        <button onClick={onTryAgain}>Try again</button>
      </div>
    ) : (
      <div className={`${styles.error} ${styles.error__404}`}>
        <div><NotFoundImage className={styles.error__404_image} /></div>
        <div className={styles.error__404_message}>
          <h1>404</h1>
          <p>{data.message ? data.message : 'Widget not found'}</p>
        </div>
      </div>
    )}
  </>
);

Error.propTypes = {
  data: PropTypes.object,
  styles: PropTypes.object.isRequired,
  onTryAgain: PropTypes.func,
};

export default Error;
