import apiClient from './apiClient';
import { API_GET_WIDGET } from '../config/apiRoutes';
import serialize from './serialize';

export const getWidget = (
  widgetId,
  sort,
  filters,
  offset = 0,
) => {
  const query = filters ? serialize(filters) : null;
  const sortOrder = sort ? serialize(sort) : null;
  return apiClient.get(API_GET_WIDGET(widgetId, offset || 0, sortOrder, query));
};

export default getWidget;
