import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as MarkerIcon } from './assets/marker.svg';
import { ReactComponent as CalendarIcon } from './assets/calendar.svg';
import { ReactComponent as ExternalIcon } from './assets/external.svg';
import { ReactComponent as StopwatchIcon } from './assets/stopwatch.svg';
import { WIDGET_TYPE_CALENDAR, WIDGET_TYPE_PARTICIPANT_SEARCH } from '../../config';

const widgetColumns = {
  NAME: 'event_name',
  DATE: 'event_date',
  TYPE: 'event_type',
  LOCATION: 'event_location',
  ASSOCIATION: 'event_association',
  PAGE: 'event_page',
  RESULTS: 'results',
  CUSTOM: 'custom',
  PARTICIPANT_NAME: 'participant_name',
};

const sortableColumns = [
  widgetColumns.DATE,
  widgetColumns.TYPE,
  widgetColumns.NAME,
  widgetColumns.LOCATION,
];

const sort = {
  ASC: 'asc',
  DESC: 'desc',
};

const DataTable = ({
  columns,
  rows,
  setSort,
  styles,
  widgetType,
  initialSort,
  isParticipantsList,
}) => {
  const [sortColumn, setSortColumn] = useState(initialSort.column);
  const [sortOrder, setSortOrder] = useState(initialSort.direction);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const onSortOrderChange = (columnId) => {
    if (!sortableColumns.find((sortable) => sortable === columnId)) {
      return;
    }
    const newOrder = sortOrder === sort.ASC ? sort.DESC : sort.ASC;
    setSortColumn(columnId);
    setSortOrder(newOrder);
    setSort({ sortOrder: `${columnId} ${newOrder}` });
  };

  const formatCell = (data, columnIndex) => {
    if (!data) {
      return null;
    }

    switch (columns[columnIndex].id) {
      case widgetColumns.NAME:
        if (widgetType === WIDGET_TYPE_PARTICIPANT_SEARCH) {
          return <>
            {data.url ? (<a target={'_blank'} rel={'noreferrer nofollow'} href={data.url}>{data.name}</a>) : <strong>{data.name}</strong>}
            {data.subEvent && <><span> - </span>{data.subEvent}</>}
          </>;
        }
        return (
          <div className={styles.table_logo}>
            {data.logo && <img src={data.logo} alt={data.name}/>}
            {data.url ? (<a target={'_blank'} rel={'noreferrer nofollow'} href={data.url}>{data.name}</a>) : data.name}
          </div>);
      case widgetColumns.DATE:
        return data.split('T')[0];
      case widgetColumns.PAGE:
      case widgetColumns.RESULTS:
      case widgetColumns.CUSTOM:
        return <a className={`${styles.table_link} ${styles.btn}`} target={'_blank'} rel={'noreferrer nofollow'} href={data.url}>{data.label}</a>;
      case widgetColumns.ASSOCIATION:
        if (typeof data === 'object') {
          return (
            <div className={styles.table_logo}>
              {data.logo && (<img className={`${styles.table_logo} ${styles.association_logo}`} src={data.logo} alt={data.name}/>)}
              {!data.logo && data.name}
            </div>);
        }
        return data;
      case widgetColumns.TYPE:
        return <span className={styles.table_event_type}>{data}</span>;
      default:
        return data;
    }
  };

  if (!columns || columns.length === 0) {
    return null;
  }

  if (isMobile) {
    if (!rows || !Object.keys(rows).length) {
      return <p>No matching records found</p>;
    }
    const cols = {};
    columns.forEach((col, idx) => {
      cols[col.id] = idx;
    });
    return rows.map((row, idx) => {
      const nameData = row[cols[widgetColumns.NAME]] || undefined;
      const pageLink = row[cols[widgetColumns.PAGE]] || undefined;
      const locationData = row[cols[widgetColumns.LOCATION]] || undefined;
      const participantData = row[cols[widgetColumns.PARTICIPANT_NAME]] || undefined;
      const dateData = row[cols[widgetColumns.DATE]] || undefined;
      const eventUrl = (pageLink && pageLink.url) || undefined;
      const resultsData = row[cols[widgetColumns.RESULTS]] || undefined;
      const customData = row[cols[widgetColumns.CUSTOM]] || undefined;
      const associationData = row[cols[widgetColumns.ASSOCIATION]] || undefined;

      if (widgetType === WIDGET_TYPE_CALENDAR) {
        return (<div className={styles.mobile_cell} key={idx}>
          <div className={styles.mobile_cell_images}>
            {nameData.logo
              && <img src={nameData.logo} alt={nameData.name} className={styles.event_logo}/>}
            {associationData && associationData.logo
             && <img className={styles.association_logo} src={associationData.logo}
                     alt={associationData.name}/>}
          </div>
          <div className={styles.mobile_cell_text}>
            {nameData && <div>
              {eventUrl ? (<a target={'_blank'}
                              rel={'noreferrer nofollow'}
                              href={eventUrl}
                              className={styles.mobile_event_link}>
                <strong>{nameData.name}</strong>
              </a>) : <strong>{nameData.name}</strong>}
            </div>}
            {(locationData || dateData) && <p className={styles.location_date}>
              {locationData && <span><MarkerIcon/> {locationData}</span>}
              {dateData && <span><CalendarIcon/> {dateData.split('T')[0]}</span>}
            </p>}
            {(resultsData || customData) && <p>
              {resultsData && <a className={`${styles.table_link} ${styles.mobile_link} ${styles.has_text} ${styles.btn}`} target={'_blank'} rel={'noreferrer nofollow'} href={resultsData.url}><StopwatchIcon/> {resultsData.label}</a>}
              {customData && <a className={`${styles.table_link} ${styles.mobile_link} ${styles.has_text} ${styles.btn}`} target={'_blank'} rel={'noreferrer nofollow'} href={customData.url}><ExternalIcon/> {customData.label}</a>}
            </p>}
          </div>
        </div>);
      }
      if (widgetType === WIDGET_TYPE_PARTICIPANT_SEARCH) {
        return (
          <div className={`${styles.mobile_cell} ${styles.mobile_participant_search}`} key={idx}>
            <div className={styles.mobile_cell_text}>
              {participantData && <p><strong>{participantData}</strong></p>}
              {nameData && <p>
                {eventUrl ? (<a target={'_blank'}
                                rel={'noreferrer nofollow'}
                                href={eventUrl}
                                className={styles.mobile_event_link}>
                  <strong>{nameData.name}</strong>
                </a>) : <strong>{nameData.name}</strong>}
                {nameData.subEvent && <><br/>{nameData.subEvent}</>}
              </p>}
              {isParticipantsList && locationData
              && <p className={styles.location_date}>{locationData}</p>}
              {!isParticipantsList && (locationData || dateData)
              && <p className={styles.location_date}>
                {locationData && <span><MarkerIcon/> {locationData}</span>}
                {dateData && <span><CalendarIcon/> {dateData.split('T')[0]}</span>}
              </p>}
            </div>
            {resultsData && <div className={styles.mobile_participant_search_results}>
              <a className={`${styles.table_link} ${styles.mobile_link} ${styles.btn}`} target={'_blank'} rel={'noreferrer nofollow'} href={resultsData.url}><StopwatchIcon/></a>
            </div>}
          </div>
        );
      }
      return null;
    });
  }

  return (
    <div className={styles.data_table_wrapper}>
      <table className={styles.data_table}>
        <thead>
        <tr>
          {columns && columns.map((col) => (
            <th className={`
            ${styles.table_header}
            ${sortableColumns.find((sortable) => sortable === col.id) ? styles.table_header__sort : ''}
            ${(sortColumn === col.id && sortOrder === sort.ASC) ? styles.table_header__sort_asc : ''}
            ${(sortColumn === col.id && sortOrder === sort.DESC) ? styles.table_header__sort_desc : ''}
            `} key={col.id} onClick={() => onSortOrderChange(col.id)}>{col.title}</th>
          ))}
        </tr>
        </thead>
        <tbody>
        {(rows && Object.keys(rows).length > 0) ? Object.keys(rows).map((row) => (
          <tr key={`row_${row}`}>
            {rows[row].map((cell, index) => (
              <td key={`cell_${index}`}>{formatCell(cell, index)}</td>
            ))}
          </tr>
        )) : (
          <tr>
            <td colSpan={columns.length} className={styles.no_records}>
              No matching records found
            </td>
          </tr>
        )}
        </tbody>
      </table>
    </div>
  );
};

DataTable.propTypes = {
  columns: PropTypes.array,
  rows: PropTypes.array,
  setSort: PropTypes.func.isRequired,
  styles: PropTypes.object,
  initialSort: PropTypes.object.isRequired,
  widgetType: PropTypes.string.isRequired,
  isParticipantsList: PropTypes.bool.isRequired,
};

export default DataTable;
