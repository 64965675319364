import { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { getWidget } from './lib/apiCalls';
import injectCss from './lib/injectCss';
import styles from './App.module.scss';
import EventsListPage from './pages/EventsListPage';
import Loading from './components/Loading';
import Error from './components/Error';

const App = ({ widgetId }) => {
  const [widget, setWidget] = useState(null);
  const [loading, setLoading] = useState(true);
  const [reloading, setReloading] = useState(false);
  const [error, setError] = useState(null);
  const currentWidgetId = widget && widget.id;

  const loadData = useCallback((queryString = null, offset = 0, sort = null) => {
    if (currentWidgetId) {
      setReloading(true);
    }
    getWidget(widgetId, sort, queryString, offset)
      .then((response) => {
        if (!currentWidgetId) {
          injectCss(styles, { ...response?.data?.configuration?.displaySettings || {} });
        }
        setWidget({
          id: widgetId,
          ...response.data,
        });
        setError(null);
      })
      .catch((err) => {
        setError({
          status: err?.response?.status || 500,
          message: err?.response?.data?.meta?.error || null,
        });
      })
      .finally(() => {
        setLoading(false);
        if (currentWidgetId) {
          setReloading(false);
        }
      });
  }, [currentWidgetId, widgetId]);

  useEffect(() => {
    if (currentWidgetId === null) {
      loadData();
    }
  }, [currentWidgetId, loadData]);

  if (loading) {
    return (<Loading className={styles.loading} />);
  }

  if (error) {
    return (<Error data={error} styles={styles} onTryAgain={loadData} />);
  }

  return <EventsListPage
    loadData={loadData}
    data={widget}
    reloading={reloading}
    styles={styles} />;
};

App.propTypes = {
  widgetId: PropTypes.string.isRequired,
};

export default App;
