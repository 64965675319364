import { useState, useRef, forwardRef } from 'react';
import PropTypes from 'prop-types';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { DateUtils } from 'react-day-picker';
import DayPickerOverlay from './DayPickerOverlay';
import 'react-day-picker/lib/style.css';
import { ReactComponent as PlusIcon } from './assets/plus-solid.svg';
import { ReactComponent as MinusIcon } from './assets/minus-solid.svg';
import { ReactComponent as CalendarIcon } from './assets/calendar-alt-solid.svg';
import { ReactComponent as SearchIcon } from './assets/search-solid.svg';
import { WIDGET_TYPE_CALENDAR, WIDGET_TYPE_PARTICIPANT_SEARCH } from '../../config';

const dateToString = (date) => {
  if (!date) {
    return '';
  }
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = (date.getDate()).toString().padStart(2, '0');
  return `${date.getFullYear()}-${month}-${day}`;
};

const Filters = ({
  styles,
  setFilters,
  setOffset,
  eventTypes = {},
  regions = {},
  settings,
  widgetType,
}) => {
  const [search, setSearch] = useState('');
  const [searchByEvent, setSearchByEvent] = useState('');
  const [searchByParticipant, setSearchByParticipant] = useState('');
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [eventLocation, setEventLocation] = useState('');
  const [eventType, setEventType] = useState('');
  const [eventDateFrom, setEventDateFrom] = useState(undefined);
  const [eventDateTo, setEventDateTo] = useState(undefined);
  const dateToRef = useRef();
  const dateFromRef = useRef();

  const renderRegions = () => (
    <div className={styles.form_group}>
      <label className={styles.form_label}>State/Province</label>
      <select
        className={styles.form_control}
        name={'province'}
        value={eventLocation}
        onChange={(e) => setEventLocation(e.target.value)}>
        <option value={''}>Select...</option>
        {Object.keys(regions)
          .map((group, index) => (
            <optgroup key={index} label={group}>
              {Object.keys(regions[group]).map((val) => (
                <option key={val} value={val}>{regions[group][val]}</option>
              ))}
            </optgroup>))
        }
      </select>
    </div>
  );

  const renderEventTypes = () => (
    <div className={styles.form_group}>
      <label className={styles.form_label}>Event type</label>
      <select
        className={styles.form_control}
        name={'event_type'}
        value={eventType}
        onChange={(e) => setEventType(e.target.value)}>
        <option>Select...</option>
        {Object.keys(eventTypes).map((val) => (
          <option key={val} value={val}>{eventTypes[val]}</option>
        ))}
      </select>
    </div>
  );

  // eslint-disable-next-line react/display-name
  const renderDateInput = forwardRef((props, ref) => (
    <input type="text" ref={ref} className={styles.form_control} {...props} />
  ));

  const handleFiltersSubmit = (event = null) => {
    if (event.type === 'keyup' && event.keyCode !== 13) {
      return;
    }
    setFilters({
      search: !search ? undefined : search,
      searchByEvent: !searchByEvent ? undefined : searchByEvent,
      searchByParticipant: !searchByParticipant ? undefined : searchByParticipant,
      eventLocation: eventLocation || undefined,
      eventType: eventType || undefined,
      eventDateFrom: dateToString(eventDateFrom) || undefined,
      eventDateTo: dateToString(eventDateTo) || undefined,
    });
    setOffset(0);
  };

  const handleSearchByEvent = (e) => {
    setSearchByParticipant('');
    return handleFiltersSubmit(e);
  };

  const handleSearchByParticipant = (e) => {
    setSearchByEvent('');
    return handleFiltersSubmit(e);
  };

  const modifiers = { start: eventDateFrom, end: eventDateTo };

  const formatDate = (dateObject) => (dateObject ? dateObject.toLocaleString('en-US', { dateStyle: 'long' }) : '');

  const parseDate = (date) => {
    const checkFormat = /^\w+[ ]\d{1,2}[,]\s\d{4}\s*$/;
    if (!checkFormat.test(date)) {
      return undefined;
    }

    const newDate = new Date(date);
    if (!DateUtils.isDate(newDate)) {
      return undefined;
    }

    return newDate;
  };

  const onShowAdvanced = (event) => {
    event.preventDefault();
    setShowAdvanced(!showAdvanced);
  };

  const renderSearchField = ({
    name, label, value, onChange, onSubmit, showSubmit = false,
  }) => (
    <div className={`${styles.form_group}`} key={name}>
      <label className={styles.form_label} htmlFor={`query_${name}`}>{label}</label>
      <div className={`${styles.filters__search} ${styles.input_group_append}`}>
        <input
          className={`${styles.form_control}`}
          type={'text'}
          name={`query_${name}`}
          id={`query_${name}`}
          onKeyUp={handleFiltersSubmit}
          value={value}
          onChange={(e) => onChange(e.target.value)}/>
        {!showSubmit && <div className={styles.form_icon}><SearchIcon aria-label={label}/></div>}
        {showSubmit && <button className={`${styles.btn} ${styles.btn_submit}`}
                  type={'button'}
                  onClick={onSubmit}>Search</button>}
      </div>
    </div>
  );

  return (
    <div className={styles.filters}>
      <div className={`${styles.filters__regular} ${widgetType === WIDGET_TYPE_PARTICIPANT_SEARCH ? styles.well : ''}`}>
        {widgetType === WIDGET_TYPE_CALENDAR && renderSearchField({
          name: 'search',
          label: 'Search for events',
          value: search,
          onChange: setSearch,
          onSubmit: handleFiltersSubmit,
        })}
        {widgetType === WIDGET_TYPE_PARTICIPANT_SEARCH && <>
          {renderSearchField({
            name: 'searchByParticipant',
            label: 'Search by registrant name',
            value: searchByParticipant,
            onChange: setSearchByParticipant,
            onSubmit: handleSearchByParticipant,
            showSubmit: true,
          })}
          <div>
            <span className={`${styles.btn_or}`}>or</span>
          </div>
          {renderSearchField({
            name: 'searchByEvent',
            label: 'Search by event',
            value: searchByEvent,
            onChange: setSearchByEvent,
            onSubmit: handleSearchByEvent,
            showSubmit: true,
          })}
        </>
        }
      </div>
      <div className={`${styles.filters__advanced} ${showAdvanced ? styles.filters__advanced_show : ''}`}>
        {settings.eventLocation && renderRegions()}
        {settings.eventType && renderEventTypes()}
        {settings.eventDate && (
          <>
            <div className={styles.form_group}>
              <label className={styles.form_label}>Date From:</label>
              <div className={`${styles.input_group} ${styles.input_group_prepend}`}>
                <div className={styles.form_icon}><CalendarIcon aria-label={'Choose date'}/></div>
                <DayPickerInput
                  keepFocus={true}
                  component={renderDateInput}
                  overlayComponent={(props) => <DayPickerOverlay
                    componentRef={dateFromRef}
                    styles={styles}
                    {...props} />}
                  ref={dateFromRef}
                  value={eventDateFrom}
                  placeholder="From"
                  formatDate={formatDate}
                  parseDate={parseDate}
                  onDayPickerShow={() => dateToRef.current.hideDayPicker()}
                  dayPickerProps={{
                    disabledDays: { after: eventDateTo },
                    toMonth: eventDateTo,
                    modifiers,
                  }}
                  onDayChange={async (day, dayModifiers, dayInput) => {
                    await setEventDateFrom(day);
                    dayInput.getInput().focus();
                  }}
                />
                {eventDateFrom && <button className={styles.clear_date}
                                          onClick={() => setEventDateFrom(null)}>
                  &times;
                </button>}
              </div>
            </div>
            <div className={styles.form_group}>
              <label className={styles.form_label}>Date To:</label>
              <div className={`${styles.input_group} ${styles.input_group_prepend}`}>
                <div className={styles.form_icon}><CalendarIcon aria-label={'Choose date'}/></div>
                <DayPickerInput
                  keepFocus={true}
                  component={renderDateInput}
                  overlayComponent={(props) => <DayPickerOverlay
                    componentRef={dateToRef}
                    styles={styles}
                    {...props} />}
                  ref={dateToRef}
                  value={eventDateTo}
                  placeholder="To"
                  formatDate={formatDate}
                  parseDate={parseDate}
                  onDayPickerShow={() => dateFromRef.current.hideDayPicker()}
                  dayPickerProps={{
                    disabledDays: { before: eventDateFrom },
                    modifiers,
                    month: eventDateFrom,
                    fromMonth: eventDateFrom,
                  }}
                  onDayChange={async (day, dayModifiers, dayInput) => {
                    await setEventDateTo(day);
                    dayInput.getInput().focus();
                  }}
                />
                {eventDateTo && <button className={styles.clear_date}
                                          onClick={() => setEventDateTo(null)}>
                  &times;
                </button>}
              </div>
            </div>
          </>
        )}
      </div>
      {(settings.eventDate || settings.eventLocation || settings.eventType) ? (
        <div><a href={'#advanced'} className={`${styles.filters__advanced_link}`} onClick={onShowAdvanced}>
          Advanced search {showAdvanced ? <MinusIcon/> : <PlusIcon/>}
        </a></div>
      ) : (<div/>)}
      {widgetType !== WIDGET_TYPE_PARTICIPANT_SEARCH
      && <div className={`${styles.filters__regular} ${styles.filters__submit}`}>
        <button className={`${styles.btn} ${styles.btn_submit}`} type={'button'} onClick={handleFiltersSubmit}>Search</button>
      </div>}
    </div>);
};

Filters.propTypes = {
  styles: PropTypes.object,
  eventTypes: PropTypes.object,
  regions: PropTypes.object,
  setFilters: PropTypes.func.isRequired,
  setOffset: PropTypes.func.isRequired,
  settings: PropTypes.object.isRequired,
  widgetType: PropTypes.string.isRequired,
};

export default Filters;
