import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import App from './App';

(function (window, document) { // eslint-disable-line
  if (typeof window.RR === 'undefined') {
    window.RR = {}; // eslint-disable-line
  }

  function CalendarWidget(config) {
    const {
      domElementId = null,
      widgetId = null,
    } = config;
    if (domElementId !== null) {
      ReactDOM.render(
                <StrictMode>
                    <App widgetId={widgetId} />
                </StrictMode>,
                document.getElementById(domElementId),
      );
    }
  }

  window.RR.calendarWidget = CalendarWidget; // eslint-disable-line

  if (process.env.NODE_ENV === 'development') {
    const path = window.location.pathname.split('/');
    CalendarWidget({
      domElementId: 'root',
      widgetId: path[2],
    });
  }
}(window, document));
