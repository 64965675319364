import PropTypes from 'prop-types';

const onClose = (ref) => ref.current.hideDayPicker();

const DayPickerOverlay = ({
  classNames, selectedDay, children, styles, componentRef, ...props
}) => (
  <div
    className={classNames.overlayWrapper}
    {...props}>
    <div className={classNames.overlay}>
      <div className={styles.day_picker__close} onClick={() => onClose(componentRef)}>&times;</div>
      {children}
    </div>
  </div>
);

DayPickerOverlay.propTypes = {
  classNames: PropTypes.object.isRequired,
  selectedDay: PropTypes.instanceOf(Date),
  children: PropTypes.node.isRequired,
  styles: PropTypes.object.isRequired,
  componentRef: PropTypes.object.isRequired,
};

export default DayPickerOverlay;
